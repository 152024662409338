import logo from './logo.svg';
import './App.css';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import ReactTypingEffect from 'react-typing-effect';
import PhotoAlbum from "react-photo-album";
import ScrollTrigger from 'react-scroll-trigger';
import { useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import axios from 'axios';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {

  const [isScroll, setIsScroll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [posts, setPosts] = useState([])
  const [connection, setConnection] = useState({})


  const onEnterViewport = () => {
    setIsScroll(true);
  }
  const onEnterMenuArea = () => {
    setIsScroll(false);
  }

  useEffect(() => {
    if (image != "")
      setShowImage(true)

  }, [image])

  useEffect(() => {
    AOS.init();
  }, [])

  useEffect(() => {

    async function fetchMyAPI() {
      const options = {
        method: 'GET',
        url: 'https://instagram-scraper-api2.p.rapidapi.com/v1.2/posts',
        params: {
          username_or_id_or_url: 'nonno_design'
        },
        headers: {
          'X-RapidAPI-Key': '0b7f3bec62msh241592fd3a61701p1ef53fjsnd3859db2026d',
          'X-RapidAPI-Host': 'instagram-scraper-api2.p.rapidapi.com'
        }
      };

      try {
        const response = await axios.request(options);
        setPosts(response.data.data.items)
        setConnection(response.data.data.user)
      } catch (error) {
        console.error(error);
      }

    }


    fetchMyAPI()

  }, [])

  console.log(connection)

  return (
    <div className="container-fluid">
      {
        isMobile &&
        <div className='row justify-content-center' style={{ position: "fixed", marginLeft: "0px", top: "0px", left: "0px", zIndex: "10", width: "100%", backgroundColor: "white", boxShadow: isScroll ? "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px" : "none" }}>
          <div className='col-12' style={{ padding: "0px" }}>
            <div style={{ height: "100px", display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                <span style={{ fontFamily: "candara", fontSize: "21px", fontWeight: "bold", color: 'black', marginLeft: "10px" }} data-aos="fade-right"><a href="/"><img src='logo.png' style={{ width: "150px" }} /></a></span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }} className='menu'>
                <button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => setIsOpen(true)}>
                  <img src='menu-line.svg' />
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      {
        !isMobile &&
        <div className='row justify-content-center' style={{ marginLeft: "0px", position: "fixed", top: "0px", left: "0px", zIndex: "10", width: "100%", backgroundColor: isScroll ? "white" : "transparent", boxShadow: isScroll ? "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px" : "none" }}>
          <div className='col-10' style={{ padding: "0px" }}>
            <div style={{ height: "150px", display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontFamily: "Metrophobic", fontSize: "40px", fontWeight: "bold", color: isScroll ? "black" : "white" }} data-aos="fade-right"><a href="/"><img src='logo.png' style={{ width: "350px" }} /></a></span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }} className='menu' data-aos="fade-left">
                <a style={{ padding: "10px", margin: "15px", fontFamily: "candara", textDecoration: "none", fontSize: "21px", color: "black" }} href='/projeler'>PROJELER</a>
                <a style={{ padding: "10px", margin: "15px", fontFamily: "candara", textDecoration: "none", fontSize: "21px", color: "black" }} href='#'>ÜRÜNLER</a>
                <a style={{ padding: "10px", margin: "15px", fontFamily: "candara", textDecoration: "none", fontSize: "21px", color: "black" }} href='#'>STÜDYO</a>
                <a style={{ padding: "10px", margin: "15px", fontFamily: "candara", textDecoration: "none", fontSize: "21px", color: "black" }} href='#'>İLETİŞİM</a>
              </div>
            </div>
          </div>
        </div>
      }

      <div className='row'>
        <div className='col-12' style={{ padding: "0px" }}>
          <ScrollTrigger onEnter={onEnterMenuArea} onExit={onEnterViewport}>
            <AwesomeSlider style={{ height: isMobile ? "1000px" : "initial" }}>
              <div style={{ position: "relative", height: isMobile ? "1000px" : "initial" }}>
                <div style={{ backgroundColor: "black", position: "absolute", top: "0px", left: "0px", width: "100%", height: isMobile ? "1000px" : "100%", opacity: "0.1" }}></div>
                <img src="nono4.jpg" style={{ width: isMobile ? "auto" : "100%", height: isMobile ? "1000px" : "auto" }} />
              </div>
              <div style={{ position: "relative", height: isMobile ? "1000px" : "initial" }}>
                <div style={{ backgroundColor: "black", position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", opacity: "0.1" }}></div>
                <img src="nono2.jpg" style={{ width: isMobile ? "auto" : "100%", height: isMobile ? "1000px" : "auto" }} />
              </div>
              <div style={{ position: "relative", height: isMobile ? "1000px" : "initial" }}>
                <div style={{ backgroundColor: "black", position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", opacity: "0.1" }}></div>
                <img src="nono3.jpg" style={{ width: isMobile ? "auto" : "100%", height: isMobile ? "1000px" : "auto" }} />
              </div>
              <div style={{ position: "relative", height: isMobile ? "1000px" : "initial" }}>
                <div style={{ backgroundColor: "black", position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", opacity: "0.1" }}></div>
                <img src="nono1.jpg" style={{ width: isMobile ? "auto" : "100%", height: isMobile ? "1000px" : "auto" }} />
              </div>
            </AwesomeSlider>
          </ScrollTrigger>
        </div>
      </div>

      <div className='row justify-content-center' style={{ height: "100%" }}>
        <div className='col-12' style={{ padding: "0px" }}>
          <div style={{ marginTop: "20px" }}>
            <div style={{ position: "relative" }}>
              <ScrollTrigger onEnter={onEnterViewport}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div data-aos="fade-up" style={{ width: "24%", position: "relative" }} onClick={() => setImage("Nonno Design_Hound_06.jpg")}><img src='Nonno Design_Hound_06.jpg' style={{ width: "100%" }} /><p style={{ color: "white", fontSize: (isMobile || isTablet) ? "18px" : "24px", position: "absolute", top: "40px", textAlign: "center", width: "100%", fontFamily: "candara" }}>HOUND CAFE</p></div>
                  <div data-aos="fade-up" style={{ width: "24%", position: "relative" }} onClick={() => setImage("GAON_18.jpg")}><img src='GAON_18.jpg' style={{ width: "100%" }} /><p style={{ color: "white", fontSize: (isMobile || isTablet) ? "18px" : "24px", position: "absolute", top: "40px", textAlign: "center", width: "100%", fontFamily: "candara" }}>BÜŞRA ZARARSIZ STUDIO</p></div>
                  <div data-aos="fade-up" style={{ width: "24%", position: "relative" }} onClick={() => setImage("vogue.png")}><img src='vogue.png' style={{ width: "100%" }} /><p style={{ color: "white", fontSize: (isMobile || isTablet) ? "18px" : "24px", position: "absolute", top: "40px", textAlign: "center", width: "100%", fontFamily: "candara" }}>AUTO VOGUE</p></div>
                  <div data-aos="fade-up" style={{ width: "24%", position: "relative" }} onClick={() => setImage("MUSTAFA REVAN_GAON_25.jpg")}> <img src='MUSTAFA REVAN_GAON_25.jpg' style={{ width: "100%" }} /><p style={{ color: "white", fontSize: (isMobile || isTablet) ? "18px" : "24px", position: "absolute", top: "40px", textAlign: "center", width: "100%", fontFamily: "candara" }}>GAON</p></div>
                </div>
              </ScrollTrigger>
            </div>
          </div>
        </div>
      </div>
      <div className='row' style={{ marginTop: "20px" }}>
        <div className='col-12' style={{ padding: "0px" }}>
          <div style={{ position: "relative" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "auto" }}>
              <div style={{ width: (isTablet || isMobile) ? "40%" : "40%" }}>
                <img src='vogue.png' style={{ width: "100%" }} />
              </div>
              <div style={{ width: (isTablet || isMobile) ? "20%" : "10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <p style={{ textOrientation: "upright", writingMode: "vertical-rl", fontFamily: "candara", fontSize: (isTablet || isMobile) ? "50px" : "120px", margin: "0px", textAlign: "center" }}>RHEA</p>
              </div>
              <div style={{ width: (isTablet || isMobile) ? "40%" : "40%" }}>
                <img src='vogue.png' style={{ width: "100%" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "50px" }}>
        <p style={{ textAlign: "center", fontFamily: "candara", fontSize: "34px", color: "black" }}>SOSYAL MEDYADA BİZ</p>
      </div>
      <div className='row justify-content-center' style={{ marginTop: "10px" }}>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6' style={{ padding: "0px" }}>
          <div>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 250: 3, 900: 3 }}
            >
              <Masonry columnsCount={3}>
                {
                  posts.length > 0 &&
                  posts.map((post, index) => {
                    if (index < 3) {
                      return (
                        <a href={"#"} style={{ textDecoration: "none", color: "black" }}>
                          <div style={{ padding: "2px", margin: "2px" }} data-aos="fade-up">
                            {

                              post.media_type === 1 &&
                              <img src={post.thumbnail_url} style={{ width: "100%", height: "auto" }} />
                            }
                            {

                              post.media_type === 8 &&
                              <img src={post.carousel_media[0].thumbnail_url} style={{ width: "100%", height: "auto" }} />
                            }


                            {/* <p style={{ fontSize: "18px", marginTop: "20px", marginBottom: "0px", fontFamily: "candara", color: "black" }}><b>{post.caption.text}</b></p> */}
                          </div>
                        </a>
                      )
                    }
                  })
                }
                {
                  posts.length == 0 &&
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <p>Yükleniyor...</p>
                    <div class="spinner-border text-dark" role="status">
                    </div>
                  </div>
                }

              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
      </div>
      <div style={{marginTop:"20px"}}>
        <a href='' style={{ color: "#e4ded4", fontSize: "24px", fontFamily: "candara", textDecoration: "none", textAlign: "center", display: "block" }}>Bizi Takip Edin!<img src='arrow-right-s-line.svg' /></a>
      </div>
      <div className='row justify-content-center' style={{ backgroundColor: "#e4ded4", paddingTop: "40px", marginTop: "50px" }}>

        <div className='col-10'>
          <div>
            <p style={{ fontSize: "30px", color: "white", textAlign: "center", fontFamily: "candara", color: "#B8B4AC", fontWeight: "bold" }}>FOLLOW</p>
          </div>
        </div>
        <div className='col-10'>
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <a href="#" style={{ margin: "10px", color: "#B8B4AC" }}><i class="fa-brands fa-instagram fa-2xl"></i></a>
            <a href="#" style={{ margin: "10px", color: "#B8B4AC" }}><i class="fa-brands fa-facebook fa-2xl"></i></a>
            <a href="#" style={{ margin: "10px", color: "#B8B4AC" }}><i class="fa-brands fa-pinterest fa-2xl"></i></a>
            <a href="#" style={{ margin: "10px", color: "#B8B4AC" }}><i class="fa-brands fa-youtube fa-2xl"></i></a>
          </div>
        </div>
        <div className='col-10'>
          <div>
            <p style={{ fontSize: "24px", margin: "0px", padding: "20px", color: "#B8B4AC", fontFamily: "candara", textAlign: "center" }}>Telif Hakkı © 2023 Nonno Design - Tüm Hakları Saklıdır.</p>
          </div>
        </div>
      </div>

      <div style={{ position: "fixed", right: "10px", bottom: "40px" }}>
        <a style={{ backgroundColor: "#e4ded4", color: "black", padding: "20px", borderRadius: "40px", height: "40px", width: "40px" }} rel="noopener" data-ux-btn="external" customborderwidth="small" data-ux="ButtonExternal" href="https://wa.me/905380341224" target="_blank" data-aid="CONTACT_INFO_WHATS_APP_REND" data-tccl="ux2.contact.whatsapp.click,click" icon="whatsApp" data-typography="false" class="x-el x-el-a c1-9i c1-9j c1-9k c1-9l c1-c c1-4n c1-9m c1-9n c1-7y c1-9o c1-2b c1-3f c1-1o c1-1p c1-1q c1-1r c1-9p c1-9q c1-9r c1-9s c1-9t c1-9u c1-2m c1-9v c1-31 c1-30 c1-2j c1-2l c1-2k c1-q c1-9w c1-9x c1-46 c1-47 c1-9y c1-9z c1-1 c1-2 c1-a0 c1-a1 c1-a2 c1-a3 c1-a4 c1-a5 c1-a6 c1-a7 c1-a8 c1-a9 c1-aa c1-ab">
          <i class="fa-brands fa-whatsapp fa-2xl"></i>
        </a>
      </div>

      {
        isMobile &&
        <div style={{ zIndex: "20", position: "fixed", top: "0px", left: "0px", backgroundColor: "#113946", height: "100%", width: "100%", transform: isOpen ? "translateY(0px)" : "translateY(-100000px)", transitionDuration: "0.5s" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "end", padding: "30px" }}>
            <button style={{ border: "none", backgroundColor: "transparent" }} onClick={() => setIsOpen(false)}>
              <img src="close-line.svg" />
            </button>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", height: "100%", marginTop: "-100px" }}>
            <a href='/projeler#' style={{ textDecoration: "none", color: "white", fontSize: "28px", fontFamily: "candara" }}>projeler</a>
            <a href='#' style={{ textDecoration: "none", color: "white", fontSize: "28px", fontFamily: "candara" }}>ürünler</a>
            <a href='#' style={{ textDecoration: "none", color: "white", fontSize: "28px", fontFamily: "candara" }}>stüdyo</a>
            <a href='#' style={{ textDecoration: "none", color: "white", fontSize: "28px", fontFamily: "candara" }}>iletişim</a>
          </div>
        </div>
      }
      {
        showImage &&
        <div style={{ position: "fixed", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "15" }}>
          <div style={{ position: "fixed", top: "0px", left: "0px", width: "100%", height: "100%", backgroundColor: "black", zIndex: "14", opacity: "0.7" }} ></div>
          <div style={{ position: "fixed", top: "0px", right: "0px", display: "flex", justifyContent: "end", alignItems: "center", zIndex: "20", cursor: "pointer" }}>
            <button style={{ border: "none", backgroundColor: "transparent", padding: "10px" }} onClick={() => setShowImage(false)}>
              <img src="cross.svg" />
            </button>
          </div>
          <div style={{ position: "fixed", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", zIndex: "16" }} onClick={() => setShowImage(false)}>
            <img src={image} style={{ width: "auto", height: "500px" }} />
          </div>
        </div>
      }

    </div>
  );
}

export default App;
